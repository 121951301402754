import React from "react"
import { Global, css } from "@emotion/core"
import "normalize.css"
import Header from "../components/header"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Reklamizer | Agencja Reklamowa Warszawa</title>
        <meta
          name="description"
          content="Od ponad 10 lat skutecznie dostarczamy
produkty reklamowe naszym klientom w Warszawie."
        />
        <meta name="robots" content="noindex, nofollow" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest"></link>
      </Helmet>
      <Global
        styles={css`
          :root {
            --primary-default: #7a2c7d;
            --primary-dark: #3f1041;
            --secondary-color: #fff59b;
            --light-gray: #f4f4f4;
            --dark-gray: #3d3d3d;
          }
          body {
            background-color: #fff;
            font-family: "Roboto", sans-serif;
          }
          .wrapper {
            width: 90vw;
            max-width: 900px;
            margin: auto;
          }
        `}
      />
      <Header />

      <main
        css={css`
          min-height: calc(100vh - 158px);
        `}
      >
        {children}
      </main>
      <div
        css={css`
          background-color: var(--dark-gray);
          color: var(--light-gray);
          text-align: center;
          padding: 20px;
        `}
      >
        <footer className="wrapper">
          Reklamizer © {new Date().getFullYear()}
        </footer>
      </div>
    </>
  )
}

export default Layout
