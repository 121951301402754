import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import logo from "../../static/logo_reklamizer.png"
import logoBackground from "../../static/logo_background.svg"
import { Twirl } from "hamburger-react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const NavLi = styled("li")`
  padding: 0.2rem;
  display: inline-block;
`

const NavLink = styled(AniLink)`
  font-size: 0.9rem;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 3px;
  color: var(--primary-default);
`

const Header = () => {
  const [hamburger, setHamburger] = useState(false)

  const mobileMenu = () => {
    return (
      <nav>
        <ul
          css={css`
            padding: 0px;
            display: flex;
            padding-left: 0px;
            justify-content: space-evenly;
            padding-bottom: 15px;
            padding-top: 13px;
            border-top: 2px solid #6a226e;
            li {
              list-style: none;
              text-align: center;
              background-color: var(--primary-default);
              color: white;
            }
            @media (max-width: 850px) {
              a {
                color: white;
                padding: 5px 7px !important;
              }
              .current {
                box-shadow: none;
                background-color: var(--primary-dark);
                padding: 5px 7px !important;
                border-radius: 3px;
                padding: 0px;
              }
            }
          `}
        >
          <li>
            <NavLink
              activeClassName="current"
              paintDrip
              duration={0.7}
              hex="#7a2c7d"
              to="/"
              className="hvr-underline-from-center"
            >
              Oferta
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="current"
              paintDrip
              duration={0.7}
              hex="#7a2c7d"
              to="/referencje"
              className="hvr-underline-from-center"
            >
              Referencje
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="current"
              paintDrip
              duration={0.7}
              hex="#7a2c7d"
              to="/kontakt"
              className="hvr-underline-from-center"
            >
              Kontakt
            </NavLink>
          </li>
        </ul>
      </nav>
    )
  }

  return (
    <div
      css={css`
        background-image: url(${logoBackground});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
        min-height: 100px;
        z-index: 999;
        position: relative;
        @media (max-width: 850px) {
          background-position: 40% top;
          background-color: var(--primary-default);
        }
        .current {
          background-color: var(--primary-default);
          border-radius: 3px;
          color: white;
          box-shadow: 0px 0px 3px #616161;
        }
      `}
    >
      <header
        className="wrapper"
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <AniLink paintDrip duration={0.7} hex="#7a2c7d" to="/">
          <img
            css={css`
              width: 350px;
              margin-top: 0.5rem;
              @media (max-width: 850px) {
                width: 250px;
                margin-top: 1.5rem;
              }
            `}
            src={logo}
            alt="Reklamizer | Agencja reklamowa Warszawa"
          />
        </AniLink>
        <nav>
          <ul
            css={css`
              @media (max-width: 850px) {
                display: none;
              }
            `}
          >
            <NavLi>
              <NavLink
                activeClassName="current"
                paintDrip
                duration={0.7}
                hex="#7a2c7d"
                to="/"
                className="hvr-underline-from-center"
              >
                Oferta
              </NavLink>
            </NavLi>
            <NavLi>
              <NavLink
                activeClassName="current"
                paintDrip
                duration={0.7}
                hex="#7a2c7d"
                to="/referencje"
                className="hvr-underline-from-center"
              >
                Referencje
              </NavLink>
            </NavLi>
            <NavLi>
              <NavLink
                activeClassName="current"
                paintDrip
                duration={0.7}
                hex="#7a2c7d"
                to="/kontakt"
                className="hvr-underline-from-center"
              >
                Kontakt
              </NavLink>
            </NavLi>
          </ul>
        </nav>
        <div
          css={css`
            margin-top: 1.2rem;
            color: white;
            @media (min-width: 850px) {
              display: none;
            }
          `}
        >
          <Twirl toggled={hamburger} toggle={setHamburger} />
        </div>
      </header>
      <div
        css={css`
          @media (min-width: 850px) {
            display: none;
          }
        `}
      >
        {hamburger ? mobileMenu() : ""}
      </div>
    </div>
  )
}

export default Header
